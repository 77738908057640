
import '../Styles/global.css'
import '../Styles/Companies.css'
import React, { useMemo,useState,useEffect } from "react";
import { FiChevronLeft,FiChevronRight,FiChevronsLeft,FiChevronsRight} from 'react-icons/fi';
import { useTable,usePagination,useFilters, useGlobalFilter ,useSortBy } from 'react-table'
import MOCK_DATA from '../helper/Report.json'
import { invoice } from '../helper/columns'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsEye } from 'react-icons/bs';
// import { GlobalFilter } from "../components/table_filter/GlobalFilter";
import { ColumnFilter } from '../components/table_filter/ColumnFilter'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import config from '../config/token';
import '../Styles/Invoices.css'
function Invoices() {
const [showRoom, setshowRoom] = useState(false)
const [Data ,setData] = useState([])
const [List ,setList] = useState([])
const columns = useMemo(() => invoice, [])
const data = useMemo(() =>Data , [Data])
const history = useNavigate()
const defaultColumn = React.useMemo(
  () => ({
    Filter: ColumnFilter
  }),
  []
)

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  state,
  gotoPage,
  pageCount,
  setPageSize,
  prepareRow,
  // setGlobalFilter
} = useTable(
  {
    columns,
    data,
    defaultColumn,
    initialState: { pageIndex: 0 }
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
)
const { pageIndex, pageSize } = state


const EditRoom = async (d)=>{
console.log(d);
var obj = {
  "companies_id": d._id
}
await  axios.post('https://api.chopeai.com/api/invoice/companyid',obj,config)
      .then(function (response) {
        console.log(response.data?.data?.data);
        setList(response.data?.data?.data)
      })
      .catch(function (error) {
        console.log(error.response.statusText);
        if(error.response.statusText === 'Unauthorized'){
          // getToken()
        }
      });
      
setshowRoom(true)
}

const cancel = ()=>{
  setshowRoom(false)
}
// 

function exportPDF(id) {
window.print()
}

const reload = ()=>{
  history(0)
}
const reset = ()=>{
  reload()
}

//https://api.chopeai.com/api/invoice/get


useEffect(() => {
  
  getData()
}, [])



const getData = async()=>{

      await  axios.get('https://api.chopeai.com/api/invoice/get',config)
      .then(function (response) {
        console.log(response.data?.data?.data);
        setData(response.data?.data?.data)
      })
      .catch(function (error) {
        console.log(error.response.statusText);
        if(error.response.statusText === 'Unauthorized'){
          // getToken()
        }
      });
    } 

const openInvoice = (e)=>{
  console.log(e.target.className);
  var target = e.target.className
 var l = target.split(' ')
 console.log(l);
 document.querySelectorAll('.li').forEach((d)=>d.style.display = 'none')
  document.getElementById(`${l[0]}`).style.display = 'block'
  // document.querySelector(`${l[1]}`).style.display = 'block'

  document.querySelectorAll('[id^="IN"]').forEach((d)=>d.style.border = 'none')
  document.getElementById(e.target.id).style.border = '2px solid #3492FF'
  document.getElementById(e.target.id).style.borderRight = 'none'
}

  return (
    <>
    <div className='appHeader'>
      <p>Invoices</p> 
    </div>
    <div className='table-container border-radius5px box-shadow'>
          <div className='manament-table-header'>
            <span> Invoices</span>
            {/* <div className='btn-divder'>
              <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button>
              <button className='add-new-btn' onClick={reset}>add new company</button>
            </div> */}

          </div>
          {/* divder */}
          <div className='justify-center'>
          <span className='divder'></span>
          </div>

          <div className='table-container'>
          <>
      <table {...getTableProps()} id='test'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}
               
                >
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
                                 <span className="table-asc-dec"  onClick={() => column.toggleSortBy()}>
                <span>{column.render('Header')}</span> 
                <span>  {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}</span> 

                  </span>
                </th>
              ))}
              <th><div className="margintop2rem">Detail</div></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td ><BsEye {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' /></td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination-section">


            <div className="pagination-page-section">
            <div>
              <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
                <span>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(pageNumber)
                }}
                style={{ width: '50px' }}
              />
            </span>{' '}
            </div>
                

        <div className="pagination-btn-section">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         <FiChevronsLeft/>
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft/> <span>Previous</span>
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
        <span>Next</span>   <FiChevronRight/>
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight/> 
        </button>
        </div>

                <div className="pagination-select-section">
                     <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 15,20,25,50,100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                   </select>
                </div>

      </div>
          </>
          </div>
        </div>

        {showRoom && <div className='invoice-wrpper'>
          <div className='inovice-container border-radius5px'>
              <div>
                <div className='flex-head'><span>Detail invoice</span> <span onClick={cancel}><AiOutlineCloseCircle/></span></div>
                <span className='text-gray'>No of Invoice</span>
              </div>
              {List.length >0 ?<div className='flex-invoice'>
              <div className='inovice-list border-radius5px'>
                {List.map((d,i)=>
                <p key={i} id={d.invoice_no} className={`lists${i+1}`} onClick={openInvoice}>{d.invoice_no}</p>)}
              </div>
              <div className='inovice-list2 border-radius5px'>
              {List.map((d,i)=>
              <div key={i} id={`lists${i+1}`} className={`li lists${i+1}`}>
               <p><span>invoice_no :</span> <span>{d.invoice_no}</span></p>
               <p><span>purchased_on :</span> <span>{d.purchased_on}</span></p>
               <p><span>renewal_on :</span> <span>{d.renewal_on}</span></p>
               <p><span>subscription_plan :</span> <span>{d.subscription_plan}</span></p>
               <p><span>amount :</span> <span>{d.amount}</span></p>
              </div>
                )}
              </div>
              </div>:"No Data Found"}

          </div>
        </div>}
    </>
  )
}

export default Invoices