import '../Styles/global.css'
import '../Styles/Companies.css'
import React, { useMemo,useState,useEffect } from "react";
import { FiChevronLeft,FiChevronRight,FiChevronsLeft,FiChevronsRight} from 'react-icons/fi';
import { useTable,usePagination,useFilters, useGlobalFilter ,useSortBy } from 'react-table'
// import MOCK_DATA from '../helper/Companies.json'
import { Companies_data } from '../helper/columns'
import { AiOutlineCalendar } from 'react-icons/ai';
// import { BsEye } from 'react-icons/bs';
// import { GlobalFilter } from "../components/table_filter/GlobalFilter";
import { ColumnFilter } from '../components/table_filter/ColumnFilter'
// import { useNavigate } from "react-router-dom";
import { BiEdit } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlineDoDisturbAlt } from 'react-icons/md';
import { BiDetail } from 'react-icons/bi';
import InputWithLable from '../components/input/InputWithLable'

// import SimpleDropDown from '../components/simpleDropdown/SimpleDropDown'
import axios from 'axios';
import config from '../config/token';
// import { AiFillCaretDown} from 'react-icons/ai'
function Companies() {
// const [showRoom, setshowRoom] = useState(false)
const [showCompany, setshowCompany] = useState(false)
const [CompanyData, setCompanyData] = useState([])
const [image ,setimage] = useState(null)
const [currencyData, setcurrencyData] = useState([])
const [LanguageData, setLanguageData] = useState([])
const [timeZoneData, settimeZoneData] = useState([])
const [CountryData, setCountryData] = useState([])
const [SubsData, setSubsData] = useState([])
const [EmployeeRole, setEmployeeRole] = useState([])
const [allowFac, setallowFac] = useState([])
// const [CompanyId, setCompanyId] = useState('')
const [CompanyName, setCompanyName] = useState('')
const [CompanyAddress, setCompanyAddress] = useState('')
const [BillingAddress, setBillingAddress] = useState('')
const [PhoneNo, setPhoneNo] = useState('')
const [Email, setEmail] = useState('')
const [ContactPersonName, setContactPersonName] = useState('')
// const [LoginNo, setLoginNo] = useState('')
const [Currency, setCurrency] = useState('')
const [Language, setLanguage] = useState('')
const [TimeZone, setTimeZone] = useState('')
const [Country, setCountry] = useState('')
const [SubsId, setSubsId] = useState('')
const [CId, setCId] = useState('')


const [Data ,setData] = useState([])
const [Data1 ,setData1] = useState([])
const columns = useMemo(() => Companies_data, [])
const data = useMemo(() =>CompanyData , [CompanyData])
// const history = useNavigate()
const defaultColumn = React.useMemo(
  () => ({
    Filter: ColumnFilter
  }),
  []
)

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  state,
  gotoPage,
  pageCount,
  setPageSize,
  prepareRow,
  // setGlobalFilter
} = useTable(
  {
    columns,
    data,
    defaultColumn,
    initialState: { pageIndex: 0 }
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
)
const { pageIndex, pageSize } = state


const EditRoom = (d)=>{
console.log(d);
// "company_name": CompanyName,
// "company_address": CompanyAddress,
// "billing_address": BillingAddress,
// "phone_no": PhoneNo,
// "email": Email,
// "contact_person_name": ContactPersonName,
// "subscription_id": SubsId,
// "currency": Currency,
// "language":Language,
// "country": Country,
// "timezone":TimeZone,
setCompanyName(d.name)
setCompanyAddress(d.company_address)
setBillingAddress(d.billing_address)
setPhoneNo(d.phone_no)
setEmail(d.email)
setContactPersonName(d.admin)
// setLoginNo('')
setCurrency(d.currency)
setLanguage(d.language)
setTimeZone(d.timezone)
setCountry(d.country)
setSubsId(d.subscription_plan)
setimage(d.logo)
setCId(d._id)
setshowCompany(true)
}

const cancel = ()=>{
  setCompanyName('')
  setCompanyAddress('')
  setBillingAddress('')
  setPhoneNo('')
  setEmail('')
  setContactPersonName('')
  // setLoginNo('')
  setCurrency('')
  setLanguage('')
  setTimeZone('')
  setCountry('')
  setSubsId('')
  setimage('')
  setallowFac([])
  setshowCompany(false)
}
// 

function exportPDF(id) {
window.print()
}

// const reload = ()=>{
//   history(0)
// }
// const reset = ()=>{
//   reload()
// }

const AddNewCompany = ()=>{
  setCompanyName('')
  setCompanyAddress('')
  setBillingAddress('')
  setPhoneNo('')
  setEmail('')
  setContactPersonName('')
  // setLoginNo('')
  setCurrency('')
  setLanguage('')
  setTimeZone('')
  setCountry('')
  setSubsId('')
  setimage('')
  setallowFac([])
  setshowCompany(true)
 console.log('add new company');
}


useEffect(() => {
  getData()
  getCurrency()
  getLanguage()
  getTimeZone()
  getCountry()
  getSubId()
  facility()
  auth()
}, [])

const facility = async ()=>{

    await  axios.get('https://api.chopeai.com/api/facility/getall',config)
    .then(function (response) {
      console.log(response.data?.data?.data);
      setData(response.data?.data?.data)
      // setfaciTable(true)
      // setauthTable(false)
    })
    .catch(function (error) {
      console.log(error.response.statusText);
      if(error.response.statusText === 'Unauthorized'){
        // getToken()
      }
    });
}

const auth = async ()=>{

    await  axios.get('https://api.chopeai.com/api/authentication/get',config)
    .then(function (response) {
      console.log(response.data?.data?.data);
      setData1(response.data?.data?.data)
      // setauthTable(true)
      // setfaciTable(false)
    })
    .catch(function (error) {
      console.log(error.response.statusText);
      if(error.response.statusText === 'Unauthorized'){
        // getToken()
      }
    });
}

const getData = async ()=>{

  await  axios.get('https://api.chopeai.com/api/companies/get',config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    setCompanyData(response.data?.data?.data)
   
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const getCurrency = async ()=>{

  await  axios.get('https://api.chopeai.com/api/utils/currency',config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    setcurrencyData(response.data?.data?.data)
   
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const getLanguage = async ()=>{

  await  axios.get('https://api.chopeai.com/api/utils/language',config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    setLanguageData(response.data?.data?.data)
   
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const getTimeZone = async ()=>{

  await  axios.get('https://api.chopeai.com/api/utils/timezone',config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    settimeZoneData(response.data?.data?.data)
   
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const getCountry = async ()=>{

  await  axios.get('https://api.chopeai.com/api/utils/country',config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    setCountryData(response.data?.data?.data)
   
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const getSubId = async ()=>{

  await  axios.get('https://api.chopeai.com/api/subscription/activeget',config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    setSubsData(response.data?.data?.data)
   
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}




const activeComp =async (d)=>{

  var obj = {
    "companiesId": d._id
  }
  await  axios.post('https://api.chopeai.com/api/companies/active',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    getData()
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}


const disableComp =async (d)=>{
  console.log(d);
  var obj = {
    "companiesId": d._id
  }
  await  axios.post('https://api.chopeai.com/api/companies/disable',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    getData()
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const deleteComp =async (d)=>{
  console.log(d);
  var obj = {
    "companiesId": d._id
  }
  await  axios.post('https://api.chopeai.com/api/companies/delete',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    getData()
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const createComp =async ()=>{
  var obj = {
    "company_name": CompanyName,
    "company_address": CompanyAddress,
    "billing_address": BillingAddress,
    "phone_no": PhoneNo,
    "email": Email,
    "contact_person_name": ContactPersonName,
    "subscription_id": SubsId,
    "currency": Currency,
    "language":Language,
    "country": Country,
    "timezone":TimeZone,
  }
console.log(obj);

var bodyFormData = new FormData();
bodyFormData.append('data',JSON.stringify(obj))

var e = document.getElementById("userProfile");
console.log(e.files[0]);
bodyFormData.append('image',e.files[0])

await  axios.post('https://api.chopeai.com/api/companies/create',bodyFormData,config)
.then(function (response) {
  console.log(response.data?.data?.data);
  if(response.data?.data?.message === "successfully created companies"){
    getData()
    setshowCompany(false)

      setCompanyName('')
      setCompanyAddress('')
      setBillingAddress('')
      setPhoneNo('')
      setEmail('')
      setContactPersonName('')
      // setLoginNo('')
      setCurrency('')
      setLanguage('')
      setTimeZone('')
      setCountry('')
      setSubsId('')
      setimage('')
  }
  
})
.catch(function (error) {
  console.log(error.response.statusText);
 
});

}

const UpdateComp = async (d)=>{
  var obj = {
    "company_name": CompanyName,
    "company_address": CompanyAddress,
    "billing_address": BillingAddress,
    "phone_no": PhoneNo,
    "email": Email,
    "contact_person_name": ContactPersonName,
    "subscription_id": SubsId,
    "currency": Currency,
    "language":Language,
    "country": Country,
    "timezone":TimeZone,
    "facility_access":"",
    "companies_id":CId
  }
console.log(obj);

var bodyFormData = new FormData();
bodyFormData.append('data',JSON.stringify(obj))

var e = document.getElementById("userProfile");
console.log(e.files[0]);
bodyFormData.append('image',e.files[0])

await  axios.post('https://api.chopeai.com/api/companies/edit',bodyFormData,config)
.then(function (response) {
  console.log(response.data?.data?.data);
  if(response.data?.data?.message === "successfully edit companies"){
    getData()
    setshowCompany(false)

      setCompanyName('')
      setCompanyAddress('')
      setBillingAddress('')
      setPhoneNo('')
      setEmail('')
      setContactPersonName('')
      // setLoginNo('')
      setCurrency('')
      setLanguage('')
      setTimeZone('')
      setCountry('')
      setSubsId('')
      setimage('')
  }
  
})
.catch(function (error) {
  console.log(error.response.statusText);
 
});

}

const getfacility_1 = (e)=>{
  console.log(e.target.checked);
  if(e.target.checked === true){
    setallowFac([...allowFac,{facility_id:e.target.id,facility_name:e.target.value}])
    console.log({facility_id:e.target.id,facility_name:e.target.value});
  }
  else{
  const data =  allowFac.filter((d)=>d.facility_name !== e.target.value)
  setallowFac(data)
  }
}


  return (
    <>
    <div className='appHeader'>
      <p>Companies</p> 
    </div>
    <div className='table-container border-radius5px box-shadow'>
          <div className='manament-table-header'>
            <span>companies</span>
            <div className='btn-divder'>
              <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button>
              <button className='add-new-btn' onClick={AddNewCompany}>Add New Company</button>
            </div>

          </div>
          {/* divder */}
          <div className='justify-center'>
          <span className='divder'></span>
          </div>

          <div className='table-container'>
          <>
      <table {...getTableProps()} id='test'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}
               
                >
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
                                 <span className="table-asc-dec"  onClick={() => column.toggleSortBy()}>
                <span>{column.render('Header')}</span> 
                <span>  {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}</span> 

                  </span>
                </th>
              ))}
              <th><div className="margintop2rem">Action</div></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td className='action-table-btns'>
                  <BiEdit {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' />
                  <AiOutlineDelete  onClick={()=>deleteComp(row.original)} className='cursor-pointer' />
                  <MdOutlineDoDisturbAlt  onClick={()=>disableComp(row.original)} className='cursor-pointer' />
                  <BiDetail  onClick={()=>activeComp(row.original)} className='cursor-pointer' />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination-section">


            <div className="pagination-page-section">
            <div>
              <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
                <span>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(pageNumber)
                }}
                style={{ width: '50px' }}
              />
            </span>{' '}
            </div>
                

        <div className="pagination-btn-section">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         <FiChevronsLeft/>
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft/> <span>Previous</span>
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
        <span>Next</span>   <FiChevronRight/>
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight/> 
        </button>
        </div>

                <div className="pagination-select-section">
                     <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 15,20,25,50,100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                   </select>
                </div>

      </div>
          </>
          </div>
        </div>
      {showCompany &&  <div className='add-company-wrapper'>
    <div className='add-company-container'>
      <div className='border-blue border-radius15px border-radius5px backgroundColor'>
        <h3>Company Details</h3>
        <div className='company-detail-list'>
        <InputWithLable placeholder='Company Name' callback={(event)=>setCompanyName(event.target.value)} value={CompanyName}/>
        <InputWithLable placeholder='Company Address: ' callback={(event)=>setCompanyAddress(event.target.value)} value={CompanyAddress}/>
        <InputWithLable placeholder='Billing Address:' callback={(event)=>setBillingAddress(event.target.value)} value={BillingAddress}/>
        <InputWithLable placeholder='Phone:' callback={(event)=>setPhoneNo(event.target.value)} value={PhoneNo}/>
        <InputWithLable placeholder='Company Email: ' callback={(event)=>setEmail(event.target.value)} value={Email}/>
        <InputWithLable placeholder='Contact Person Name: *' callback={(event)=>setContactPersonName(event.target.value)} value={ContactPersonName}/>
        <div className='input-withlable-container '>
        <span>Currency</span>

        {/* <SimpleDropDown placeholder='Currency'/> */}

                 <div className='input-container'>
     
   
              {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
              <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setCurrency(e.target.value)} value={Currency}>
              <option value="" disabled selected >Currency</option> 
              
              { currencyData.map((d,i)=>{
            return  <option key={i} value={d.name}>{d.name}</option>
              })
              }
              </select>
               </div>
        </div>
        <div className='input-withlable-container '>
        <span>Language</span>

        {/* <SimpleDropDown placeholder='Language'/> */}
                <div className='input-container'>
            
          
            {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setLanguage(e.target.value)} value={Language}>
                <option value="" disabled selected >Language</option> 
                
                { LanguageData.map((d,i)=>{
              return  <option key={i} value={d.name}>{d.name}</option>
                })
                }
                </select>
              </div>

        </div>
        <div className='input-withlable-container '>
        <span>TimeZone</span>

        {/* <SimpleDropDown placeholder='TimeZone'/> */}
        <div className='input-container'>
            
          
            {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setTimeZone(e.target.value)} value={TimeZone}>
                <option value="" disabled selected >TimeZone</option> 
                
                { timeZoneData.map((d,i)=>{
              return  <option key={i} value={d.name}>{d.name}</option>
                })
                }
                </select>
              </div>


        </div>
        <div className='input-withlable-container '>
        <span>Country</span>
        
        <div className='input-container'>
            
          
            {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setCountry(e.target.value)} value={Country}>
                <option value="" disabled selected >Country</option> 
                
                { CountryData.map((d,i)=>{
              return  <option key={i} value={d.name}>{d.name}</option>
                })
                }
                </select>
              </div>
        {/* <SimpleDropDown placeholder='Country'/> */}
        </div>

        <div className='input-withlable-container '>
        <span>Subcripction Id</span>
        
        <div className='input-container'>
            
          
            {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setSubsId(e.target.value)} value={SubsId}>
                <option value="" disabled selected >Subcripction</option> 
                
                { SubsData.map((d,i)=>{
              return  <option key={i} value={d._id}>{d.name}</option>
                })
                }
                </select>
              </div>
        {/* <SimpleDropDown placeholder='Country'/> */}
        </div>
        {/* <InputWithLable placeholder='Login no' callback={(event)=>setLoginNo(event.target.value)} value={LoginNo}/> */}

        <div className='facility-section'>
        <span className='header'>Facility Access</span>
        <div className='flex-column-with-row'>
             <div>
                {Data.map((d,i)=>{
                return <><div className='align-center'>
                                <input type="checkbox"  id={d._id} name="facility_1" value={d.facility} onChange={(e)=>getfacility_1(e)}/>
                                <label htmlFor="vehicle3" className='upperCase'>{d.facility}</label>
                  </div>
                  </>
                })

                }
            </div>
            
            {allowFac?<div className='sectionn'>
            
            <div className='input-container'>
                {/* {JSON.stringify(allowFac)} */}
            <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setEmployeeRole(e.target.value)} value={EmployeeRole}>
            <option value="" disabled selected >Authentication</option> 

            { allowFac.map((d,i)=>{
            return  <option key={i} value={d.facility_name} className='upperCase'>{d.facility_name}</option>
            })
            }
            </select>
            </div>

            {Data1.map((d,i)=>{
                return <><div className='align-center'>
                                <input type="checkbox"  id={d._id} name="facility" value={d.name} onChange={(e)=>console.log(e)}/>
                                <label htmlFor="vehicle3" className='upperCase'>{d.name}</label>
                  </div>
                  </>
                })

                }
            </div>:null}
        </div>
        </div>

        


        <div className='facility-section'>
        <span className='header'>Employee Image</span>
        <div className='flex-column-with-row'>
       <input className='file-selection' id='userProfile' type='file'/>
       {image && <div className="user-profile-wrapper">
                            <img src={image} alt="user-Profile-img"></img>
      </div>}
        </div>
        </div>
              <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>Cancel</button>
                  {!CId?<button className='create-btn' onClick={createComp}>Create</button>:
                  <button className='create-btn' onClick={UpdateComp}>Update</button>}
              </div>

        </div>

        
      </div>
    </div>
    </div>
    
    }
    </>
  )
}

export default Companies