import React from "react";
import {Outlet,Navigate } from "react-router-dom";
import SideBar from '../components/sideBar/SideBar';
import axios from "axios";
function ProtectedRoute({ component: Component, ...restOfProps }) {
  let auth = JSON.parse(localStorage.getItem('user')) 
  let call = JSON.parse(localStorage.getItem('call')) 
    // const navigate = useNavigate();
    console.log(auth);
    var today = new Date();
   console.log(today.getTime());
   console.log(call);
   console.log(call-today.getTime());

   const getToken =async ()=>{
    var token =JSON.parse(localStorage.getItem('user')) 
    var obj = {
        "refreshToken": token.token.refreshToken,
    }
    await  axios.post('https://api.chopeai.com/api/utils/refreshToken',obj)
    .then(function (response) {
      console.log(response.data?.data.access_token);
      localStorage.setItem('accessToken',response.data?.data.access_token)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

   if(call === today.getTime()){
    alert('expired')
    getToken()
   }

   setTimeout(() => {
    getToken()
   }, call-today.getTime());
 
    return (
      auth?
      <>  <div style={{display:'flex'}}>
          <SideBar/>
          <div style={{flexGrow:1,minHeight:'100vh'}} className='content-container'>
          <Outlet/>
          </div>
          </div>
      </> : <Navigate to='/login'/>
    )
}

export default ProtectedRoute;