import React from 'react'
import './InputWithLable.css'
function InputWithLable({placeholder,type,callback,value,disabled}) {
  // const [data, setdata] = useState(null)
  const handle = (e)=>{
    // console.log(e.focus());
    e.currentTarget.focus()
  }
  return (
    <>
    <div className='input-withlable-container '>

    <span>{placeholder}</span>
    <input className='input-field' onClick={handle} disabled={disabled} type={type}  name="usrnm" onChange={(e)=>callback(e)} value={value}/>
  </div>
    </>
  )
}

export default InputWithLable