import React, { useMemo,useState,useEffect } from "react";
import InputWithLable from '../components/input/InputWithLable'
import '../Styles/Setting.css'
import axios from "axios";
import configs from '../config/token';
function Setting() {
  const [Data ,setData] = useState([])
  const [name, setName] = useState('');
  const [email, setemail] = useState('');
  const [bill, setbill] = useState('');
  const [phone, setphone] = useState('');
  const [cperson, setcperson] = useState('');
  const [Currency, setCurrency] = useState('');
  const [lan, setlan] = useState('');
  const [password, setpassword] = useState('');
  const [Theme, setTheme] = useState('');
  const [image ,setimage] = useState(null)
  const [currencyData, setcurrencyData] = useState([])
const [LanguageData, setLanguageData] = useState([])
  useEffect(() => {
  
    getData()
    getCurrency()
    getLanguage()
  }, [])
  
  const getCurrency = async ()=>{

    await  axios.get('https://api.chopeai.com/api/utils/currency',configs)
    .then(function (response) {
      console.log(response.data?.data?.data);
      setcurrencyData(response.data?.data?.data)
     
    })
    .catch(function (error) {
      console.log(error.response.statusText);
     
    });
  }
  
  const getLanguage = async ()=>{
  
    await  axios.get('https://api.chopeai.com/api/utils/language',configs)
    .then(function (response) {
      console.log(response.data?.data?.data);
      setLanguageData(response.data?.data?.data)
     
    })
    .catch(function (error) {
      console.log(error.response.statusText);
     
    });
  }
  const getData = async()=>{

        await  axios.get('https://api.chopeai.com/api/superadmin/setting',configs)
        .then(function (response) {
          console.log(response.data?.data?.data);
          const data = response.data?.data?.data
          setData(response.data?.data?.data)
          setName(data.company_address)
          setemail(data.email)
          setbill(data.billing_address)
          setphone(data.phone_no)
          setcperson(data.person_name)
          setCurrency(data.currency)
          setlan(data.language)
          setTheme(data.themes)
          setimage(data.logo)
        })
        .catch(function (error) {
          console.log(error.response.statusText);
          if(error.response.statusText  === 'Unauthorized'){
            // getToken() https://api.chopeai.com/api/superadmin/setting
          }
        });
      } 

      const updateSettings = async()=>{
        console.log(configs.headers.Authorization);
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": configs.headers.Authorization
            // "Accept": "application/json",
          }
        }
        var bodyFormData = new FormData();
        var obj = {
          company_address:name,
          billing_address: bill,
          phone_no: phone,
          person_name: cperson,
          currency: Currency,
          language: lan,
          themes: Theme,
          // email:email
      }
      console.log(obj);
      bodyFormData.append('data',JSON.stringify(obj))

var e = document.getElementById("userProfile");
console.log(e.files[0]);
bodyFormData.append('image',e.files[0])

        await  axios.post('https://api.chopeai.com/api/superadmin/setting',bodyFormData,config)
        .then(function (response) {
          console.log(response.data?.data?.data);
          getData()
        })
        .catch(function (error) {
          console.log(error.response.statusText);
         
        });
      }

  return (
    <>
    <div className='setting-wrapper box-shadow'>
  <div className='employee-container border-radius5px'>
    <div className='  border-radius15px cardd'>
      <div className='employee-detail-list'>
      <InputWithLable placeholder='Company Address: ' callback={(event)=>setName(event.target.value)} value={name}/>
      {/* <InputWithLable placeholder='Last Name'/> */}
      <InputWithLable placeholder='Billing Address:' callback={(event)=>setbill(event.target.value)} value={bill}/>
      <InputWithLable placeholder='Phone:' callback={(event)=>setphone(event.target.value)} value={phone}/>
      <InputWithLable placeholder='Company Email: ' callback={(event)=>setemail(event.target.value)} value={email}/>
      <InputWithLable placeholder='Contact Person Name: *' callback={(event)=>setcperson(event.target.value)} value={cperson}/>
      {/* <InputWithLable placeholder='Currency' callback={(event)=>setCurrency(event.target.value)} value={Currency}/>
      <InputWithLable placeholder='Language: ' callback={(event)=>setlan(event.target.value)} value={lan}/> */}
      <div className='input-withlable-container '>
        <span>Currency</span>

        {/* <SimpleDropDown placeholder='Currency'/> */}

                 <div className='input-container'>
     
   
              {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
              <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setCurrency(e.target.value)} value={Currency}>
              <option value="" disabled selected >Currency</option> 
              
              { currencyData.map((d,i)=>{
            return  <option key={i} value={d.name}>{d.name}</option>
              })
              }
              </select>
               </div>
        </div>
        <div className='input-withlable-container '>
        <span>Language</span>

        {/* <SimpleDropDown placeholder='Language'/> */}
                <div className='input-container'>
            
          
            {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setlan(e.target.value)} value={lan}>
                <option value="" disabled selected >Language</option> 
                
                { LanguageData.map((d,i)=>{
              return  <option key={i} value={d.name}>{d.name}</option>
                })
                }
                </select>
              </div>

        </div>
      <InputWithLable placeholder='Theme' callback={(event)=>setTheme(event.target.value)} value={Theme}/>
      <div className='facility-section'>
      <span className='header'>Company Logo</span>
      <div className='flex-column-with-row'>
     <input className='file-selection' id="userProfile" type='file'/>
     <div className="user-profile-wrapper">
                            <img src={image} alt="user-Profile-img"></img>
      </div>
      </div>
      </div>
      <div className='create-btn-wrapper'>
      <button className='create-btn' onClick={updateSettings} >Update</button>
      </div>
      </div>

      
    </div>
  </div>
  </div>
  </>
  )
}

export default Setting