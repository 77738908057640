// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Montserrat', sans-serif;\n}\n\na{\n  text-decoration: none;\n  color: #fff;\n}\n\nbutton{\n  padding: .5rem 2rem;\n  outline: none;\n  border: none;\n  cursor: pointer;\n}\n\nimg{\n  height: 100%;\n  width: 100%;\n}\n\n\nli{\n  text-decoration: none;\n  list-style: none;\n}\n\n#root{\n  min-height: 100vh;\n}\n\nbody{\n  background-color: #292929;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;;AAGA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');\n*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Montserrat', sans-serif;\n}\n\na{\n  text-decoration: none;\n  color: #fff;\n}\n\nbutton{\n  padding: .5rem 2rem;\n  outline: none;\n  border: none;\n  cursor: pointer;\n}\n\nimg{\n  height: 100%;\n  width: 100%;\n}\n\n\nli{\n  text-decoration: none;\n  list-style: none;\n}\n\n#root{\n  min-height: 100vh;\n}\n\nbody{\n  background-color: #292929;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
