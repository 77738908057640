import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import ProtectedRoute from './pages/ProtectedRoute';
import Error from './pages/Error';
import Dashboard from './pages/Dashboard';
import Companies from './pages/Companies';
import PaymentGateway from './pages/PaymentGateway';
import SubscriptionPlans from './pages/SubscriptionPlans';
import Invoices from './pages/Invoices';
import SuperAdmins from './pages/SuperAdmins';
import Setting from './pages/Setting';
// import Register from './pages/Register';
import ForegetPassword from './pages/ForegetPassword';
import Authentication from './pages/Authentication';

function App() {
  return (
<>
<Router >


            <Routes>
             <Route element={<ProtectedRoute/>}>
                   {/* Protected route */}
                 <Route exact path='/' element={< Dashboard />}></Route>
                 <Route exact path='/companies' element={< Companies />}></Route>
                 <Route exact path='/payment-gateway' element={< PaymentGateway />}></Route>
                 <Route exact path='/subscription-plans' element={< SubscriptionPlans />}></Route>
                 <Route exact path='/invoices' element={< Invoices />}></Route>
                 <Route exact path='/super-admins' element={< SuperAdmins />}></Route>
                 <Route exact path='/setting' element={< Setting />}></Route>
                 <Route exact path='/authentication' element={< Authentication />}></Route>
               

                   
             </Route>
                 <Route exact path='/login' element={< Login />}></Route>
                 {/* <Route exact path='/register' element={< Register />}></Route> */}
                 <Route exact path='/forget-password' element={< ForegetPassword />}></Route>
                 <Route exact path='*' element={< Error />}></Route>

   
          </Routes>



       </Router>

</>
  );
}

export default App;
