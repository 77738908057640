// import { format } from 'date-fns'

// export const COLUMNS = [
//   {
//     Header: 'S.No',
//     accessor: '_id',
//     Cell: (row) => {
//       return <div>{row.row.id*1+1}</div>;
//   },
//   },
//   {
//     Header: 'Room Name',
//     accessor: 'room_name',
//     sticky: 'left'
//   },
//   {
//     Header: 'No Pax',
//     accessor: 'no_of_pax',

//   },
//   {
//     Header: 'List Pax',
//     accessor: 'list_of_pax',

//   },
//   {
//     Header: 'Name',
//     accessor: 'organiser_name',
//     sticky: 'left'
//   },
//   {
//     Header: 'Booked Date',
//     accessor: 'booking_date',
//     // Cell: ({ value }) => {
//     //   return format(new Date(value), 'dd/MM/yyyy')
//     // }
//   },
//   {
//     Header: 'Reserved Date',
//     accessor: 'reserved_date',
//     // Cell: ({ value }) => {
//     //   return format(new Date(value), 'dd/MM/yyyy')
//     // }
//   },
//   {
//     Header: 'Start Time',
//     accessor: 'start_time'
//   },
//   {
//     Header: 'End Time',
//     accessor: 'end_time'
//   },

//   {
//     Header: 'Status',
//     accessor: 'current_status',
//     Cell: s => {
//      return<>
//            <span className={
//       s.value === 'Completed'?'greencolor'
//       :s.value === 'Reserved'?'orangecolor':
//        s.value === 'Occupied'?'redcolor':null}>
        
//       </span>
//       {s.value}
//      </>

//   }
//   },
// ]

// export const GROUPED_COLUMNS = [
//   {
//     Header: 'Id',
//     Footer: 'Id',
//     accessor: 'id'
//   },
//   {
//     Header: 'Name',
//     Footer: 'Name',
//     columns: [
//       {
//         Header: 'First Name',
//         Footer: 'First Name',
//         accessor: 'first_name'
//       },
//       {
//         Header: 'Last Name',
//         Footer: 'Last Name',
//         accessor: 'last_name'
//       }
//     ]
//   },
//   {
//     Header: 'Info',
//     Footer: 'Info',
//     columns: [
//       {
//         Header: 'Date of Birth',
//         Footer: 'Date of Birth',
//         accessor: 'date_of_birth'
//       },
//       {
//         Header: 'Country',
//         Footer: 'Country',
//         accessor: 'country'
//       },
//       {
//         Header: 'Phone',
//         Footer: 'Phone',
//         accessor: 'phone'
//       }
//     ]
//   }
// ]



// export const UserMan = [
//   {
//     Header: 'S.No',
//     accessor: '_id',
//     Cell: (row) => {
//       return <div>{row.row.id*1+1}</div>;
//   },
//   // disableSortBy: true,
//   // disableFilters: true,
//   },

//   {
//     Header: 'Name',
//     accessor: 'name',
//     sticky: 'left'
//   },
//   {
//     Header: 'Email',
//     accessor: 'email',
//     sticky: 'left'
//   },
//   {
//     Header: 'Designation',
//     accessor: 'designation',
//     sticky: 'left'
//   },
//   {
//     Header: 'Department',
//     accessor: 'department',
//     sticky: 'left'
//   },
//   {
//     Header: 'Phone_no',
//     accessor: 'phoneno',
//     sticky: 'left'
//   },
// ]


// export const MasterOcu = [
//   {
//     Header: 'S.No',
//     accessor: '_id',
//     Cell: (row) => {
//       return <div>{row.row.id*1+1}</div>;
//   },
//   // disableSortBy: true,
//   // disableFilters: true,
//   },

//   {
//     Header: 'Room Name',
//     accessor: 'room_name',

//   },
//   {
//     Header: 'Room Id',
//     accessor: 'device_id',
//     width:100
//   },
//   {
//     Header: 'No-Pack',
//     accessor: 'no_of_pax',
//   },

//   {
//     Header: 'Status',
//     accessor: 'status',

//   },

// ]


export const Reports = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id*1+1}</div>;
  },
  },

  {
    Header: 'Room Name',
    accessor: 'room_name',
    sticky: 'left'
  },
  {
    Header: 'No-Pack',
    accessor: 'no_of_pax',
    sticky: 'left'
  },
  {
    Header: 'List Pax',
    accessor: 'list_of_pax',
  },
  {
    Header: '	Organiser Name',
    accessor: 'organiser_name',
    sticky: 'left'
  },
  {
    Header: 'Booking Date',
    accessor: 'booking_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Reserved Date',
    accessor: 'reserved_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Start Time',
    accessor: 'start_time',
    sticky: 'left'
  },
  {
    Header: 'End Time',
    accessor: 'end_time',
    sticky: 'left'
  },
  {
    Header: 'Current Status',
    accessor: 'current_status',
    sticky: 'left'
  },

]


export const invoice = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id*1+1}</div>;
  },
  },

  {
    Header: 'Company Name',
    accessor: 'company_name',
    sticky: 'left'
  },
  {
    Header: 'Invoice No',
    accessor: 'invoice_no',
    sticky: 'left'
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Total Renewal',
    accessor: 'total_renewal',
    sticky: 'left'
  },
  {
    Header: 'Total Amount',
    accessor: 'total_amount',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Pay Date',
    accessor: 'pay_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Next Pay Date',
    accessor: 'next_pay_date',
    sticky: 'left'
  },
  

]


export const Companies_data = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id*1+1}</div>;
  },
  },

  {
    Header: 'Companies ',
    accessor: 'name',
  },
  {
    Header: 'Logins',
    accessor: 'logins',
  },
  {
    Header: 'Subcripction Plan',
    accessor: 'subscription_plan',
  },
  {
    Header: 'Purchased On ',
    accessor: 'purchased_on',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Renewal On',
    accessor: 'renewal_on',
  },

]

// export const EmailLogs = [
//   {
//     Header: 'S.No',
//     accessor: 'id',
//     width: 10,
//   },

//   {
//     Header: 'Email',
//     accessor: 'email',
//     width: 80,
//   },
//   {
//     Header: 'Subject',
//     accessor: 'subject',
//   },
//   {
//     Header: 'Date',
//     accessor: 'date',
//     // Cell: ({ value }) => {
//     //   return format(new Date(value), 'dd/MM/yyyy')
//     // }
//   },

// ]



export const SuperAdmin = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id*1+1}</div>;
  },
  },

  {
    Header: 'Name',
    accessor: 'name',
    sticky: 'left'
  },
  {
    Header: 'Email id',
    accessor: 'email',
    sticky: 'left'
  },
  {
    Header: 'Password',
    accessor: 'password',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  }
]


export const Plans = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id*1+1}</div>;
  },
  },

  {
    Header: 'Plan Name',
    accessor: 'name',
    sticky: 'left'
  },
  {
    Header: 'Strip ID',
    accessor: 'strip_id',
    sticky: 'left'
  },
  {
    Header: 'User count',
    accessor: 'user_count',
    sticky: 'left'
  },
  {
    Header: 'Price',
    accessor: 'price',
    sticky: 'left'
  },
  {
    Header: 'Status',
    accessor: 'status',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  }
]



export const facilitylist = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id*1+1}</div>;
  },
  },

  {
    Header: 'Facility',
    accessor: 'facility',
    sticky: 'left'
  },
  // {
  //   Header: 'Authentication ',
  //   accessor: 'Authentication ',
  //   sticky: 'left'
  // },

]

export const authList = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id*1+1}</div>;
  },
  },

  {
    Header: 'Authentication ',
    accessor: 'name',
    sticky: 'left'
  },

]