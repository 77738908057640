
import '../Styles/global.css'
import '../Styles/SuperAdmins.css'
import React, { useMemo,useState,useEffect } from "react";
import { FiChevronLeft,FiChevronRight,FiChevronsLeft,FiChevronsRight} from 'react-icons/fi';
import { useTable,usePagination,useFilters, useGlobalFilter ,useSortBy } from 'react-table'
import MOCK_DATA from '../helper/Report.json'
import { SuperAdmin } from '../helper/columns'
import { AiOutlineDelete } from 'react-icons/ai';
import { IoWarningOutline } from 'react-icons/io5';
import { BiEditAlt } from 'react-icons/bi';

// import { GlobalFilter } from "../components/table_filter/GlobalFilter";
import { ColumnFilter } from '../components/table_filter/ColumnFilter'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InputWithLable from '../components/input/InputWithLable'
function SuperAdmins() {
const [showRoom, setshowRoom] = useState(false)
const [deletePopup, setdeletePopup] = useState(false)
const [adminData ,setadminData] = useState([])
const [name, setName] = useState('');
const [email, setemail] = useState('');
const [password, setpassword] = useState('');
const [cpassword, setcpassword] = useState('');
const [id, setid] = useState('');
const [mode, setmode] = useState('Create');
const [superadminId, setsuperadminId] = useState('');

const columns = useMemo(() => SuperAdmin, [])
const data = useMemo(() =>adminData , [adminData])
const history = useNavigate()
const defaultColumn = React.useMemo(
  () => ({
    Filter: ColumnFilter
  }),
  []
)

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  state,
  gotoPage,
  pageCount,
  setPageSize,
  prepareRow,
  // setGlobalFilter
} = useTable(
  {
    columns,
    data,
    defaultColumn,
    initialState: { pageIndex: 0 }
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
)
const { pageIndex, pageSize } = state


const EditRoom = (d)=>{
console.log(d);
setshowRoom(true)
setid(d._id)
 setName(d.name)
 setemail(d.email)
 setmode('Update')
//  setpassword(d.password)
//  setcpassword(d.password)

}

const cancel = ()=>{
  setName('')
  setemail('')
  setpassword('')
  setcpassword('')
  setid('')
  setshowRoom(false)
}
// 

function exportPDF(id) {
window.print()
}
const getToken =async ()=>{
  var token =JSON.parse(localStorage.getItem('user')) 
  var obj = {
      "refreshToken": token.token.refreshToken,
  }
  await  axios.post('https://api.chopeai.com/api/utils/refreshToken',obj)
  .then(function (response) {
    console.log(response.data?.data.access_token);
    localStorage.setItem('accessToken',response.data?.data.access_token)
  })
  .catch(function (error) {
    console.log(error);
  });
}
useEffect(() => {

  getData()

}, [])


const getData = async()=>{
  var token =localStorage.getItem('accessToken')
      var config = {
        headers:  {
          "Authorization":'Bearer '  + token,
        }
      }
      await  axios.get('https://api.chopeai.com/api/superadmin/get',config)
      .then(function (response) {
        console.log(response.data?.data?.data);
        setadminData(response.data?.data?.data)
      })
      .catch(function (error) {
        console.log(error.response.statusText);
        if(error.response.statusText === 'Unauthorized'){
          getToken()
        }
      });
    } 

const createAdmin = async ()=>{
  var obj = {
    name,
    email,
    password
  }
  if(!name){
    alert('Please Enter Name')
  }
  else if(!email){
    alert('Please Enter email')
  }
  else if(!password){
    alert('Please Enter password')
  }
  else if(!cpassword){
    alert('Please Re-Enter password')
  }
  else if(password !== cpassword){
    alert('Please Mismatch')
  }
  else{

      var token =localStorage.getItem('accessToken')
          var config = {
            headers:  {
              "Authorization":'Bearer '  + token,
            }
          }
          await  axios.post('https://api.chopeai.com/api/superadmin/add',obj,config)
          .then(function (response) {
            console.log(response);
            //successfully created superadmin
            //already found superadmin email
            if(response.data?.data?.message === 'successfully created superadmin'){
              getData()
              setshowRoom(false)
            }
            if(response.data?.data?.message === 'already found superadmin email'){
              alert('User Exist')
            }
          })
          .catch(function (error) {
            console.log(error.response.statusText);
            if(error.response.statusText === 'Unauthorized'){
              getToken()
            }
          });

  }
}
const UpdateAdmin =async ()=>{
  let obj
  if(!password){
     obj = {
      id,
      name ,
      email,
      
    }
  }
  else{
     obj = {
      id,
      name ,
      email,
      password
    }
  }

   if(password !== cpassword){
    alert('Please Mismatch')
  }
  else{

      var token =localStorage.getItem('accessToken')
          var config = {
            headers:  {
              "Authorization":'Bearer '  + token,
            }
          }
          await  axios.post('https://api.chopeai.com/api/superadmin/edit',obj,config)
          .then(function (response) {
            console.log(response);
            getData()
            setshowRoom(false)
          })
          .catch(function (error) {
            console.log(error.response.statusText);
           
          });

  }
}
const deleteAdmin =async ()=>{
  var token =localStorage.getItem('accessToken')
  var config = {
    headers:  {
      "Authorization":'Bearer '  + token,
    }
  }
 var obj = {
  superadminId,
  }
  await  axios.post('https://api.chopeai.com/api/superadmin/delete',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.message);
    if(response.data?.data?.message ==='successfully delete superadmin'){
      // window.location.reload()
      getData()
  setdeletePopup(false)

    }
   
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const reload = ()=>{
  history(0)
}
const addAdmin = async()=>{
  setshowRoom(true)
  setmode('Create')
  setName('')
  setemail('')
  setcpassword('')
  setpassword('')
}
const reset = ()=>{
  reload()
}
const deleteRow = (d)=>{
  console.log(d._id);
  setsuperadminId(d._id)
  setshowRoom(false)
  setdeletePopup(true)
}
const canceldeleteRow = ()=>{
  setdeletePopup(false)
}

  return (
    <>
    <div className='appHeader'>
      <p>Super Admins</p> 
    </div>
    <div className='table-container border-radius5px box-shadow'>
          <div className='manament-table-header'>
            <span> Super Admins</span>
            <div className='btn-divder'>
              {/* <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button> */}
              <button className='add-new-btn' onClick={addAdmin}>Add New Admin</button>
            </div>

          </div>
          {/* divder */}
          <div className='justify-center'>
          <span className='divder'></span>
          </div>

          <div className='table-container'>
          <>
      <table {...getTableProps()} id='test'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}
               
                >
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
                                 <span className="table-asc-dec"  onClick={() => column.toggleSortBy()}>
                <span>{column.render('Header')}</span> 
                <span>  {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}</span> 

                  </span>
                </th>
              ))}
              <th><div className="margintop2rem">Action</div></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td className='action-table-btns' {...row.getRowProps()}>
                  <BiEditAlt  onClick={()=>EditRoom(row.original)} className='cursor-pointer'/>
                  <AiOutlineDelete  onClick={()=>deleteRow(row.original)} className='cursor-pointer' />

                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination-section">


            <div className="pagination-page-section">
            <div>
              <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
                <span>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(pageNumber)
                }}
                style={{ width: '50px' }}
              />
            </span>{' '}
            </div>
                

        <div className="pagination-btn-section">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         <FiChevronsLeft/>
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft/> <span>Previous</span>
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
        <span>Next</span>   <FiChevronRight/>
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight/> 
        </button>
        </div>

                <div className="pagination-select-section">
                     <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 15,20,25,50,100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                   </select>
                </div>

      </div>
          </>
          </div>
        </div>


        {showRoom &&  <div className='add-admin-wrapper'>
    <div className='add-admin-container'>
      <div className='border-blue border-radius15px border-radius5px backgroundColor'>
        <h3>Edit Admin</h3>
        <div className='admin-detail-list'>
        <InputWithLable placeholder='Name' callback={(event)=>setName(event.target.value)} value={name}/>
        <InputWithLable placeholder='Email' callback={(event)=>setemail(event.target.value)} value={email}/>
        <InputWithLable placeholder='Password' callback={(event)=>setpassword(event.target.value)} value={password}/>
        <InputWithLable placeholder='Confirm Password' callback={(event)=>setcpassword(event.target.value)} value={cpassword}/>

        </div>
        <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>Cancel</button>
                  {mode==='Create'?
                  <button className='create-btn' onClick={createAdmin}>Create</button>
                  :mode==='Update'?
                  <button className='create-btn' onClick={UpdateAdmin}>Update</button>:null
                  }
              </div>
        
      </div>
    </div>
    </div>
    
    }
    {deletePopup && 
    
    <div className='add-admin-wrapper'>
    <div className='add-admin-container'>
      <div className='border-blue border-radius15px border-radius5px backgroundColor'>
        {/* <h3>Delete Admin</h3> */}
        <div className='delete-admin'>
        <h3>Are You Sure Delete</h3>
       <h1><IoWarningOutline/></h1> 
        </div>
        <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={canceldeleteRow}>Cancel</button>
                  <button className='create-btn' onClick={deleteAdmin}>Delete</button>
              </div>
        
      </div>
    </div>
    </div>
    
    }
    </>
  )
}

export default SuperAdmins