import React from 'react'
import '../Styles/Error.css'
import logo from '../assests/err2.svg'
import { useNavigate } from "react-router-dom";
import {FiChevronsLeft} from 'react-icons/fi';
function Error() {
  const navigate =useNavigate()
  const handlechange = ()=>{
      navigate('/')
  }
  return (
    <>
    <div className='error-container'>
        <button className='back-to-home' onClick={handlechange}><FiChevronsLeft/> Back To Home</button>
        <img src={logo} alt='err' />
    </div>
    
    </>
  )
}

export default Error