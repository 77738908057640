

import '../Styles/global.css'
import '../Styles/SubscriptionPlans.css'
import React, { useMemo,useState,useEffect } from "react";
import { FiChevronLeft,FiChevronRight,FiChevronsLeft,FiChevronsRight} from 'react-icons/fi';
import { useTable,usePagination,useFilters, useGlobalFilter ,useSortBy } from 'react-table'
import MOCK_DATA from '../helper/Report.json'
import { Plans } from '../helper/columns'
import { AiOutlineCalendar } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlineDoDisturbAlt } from 'react-icons/md';
import { BiDetail } from 'react-icons/bi';
// import { GlobalFilter } from "../components/table_filter/GlobalFilter";
import { ColumnFilter } from '../components/table_filter/ColumnFilter'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InputWithLable from '../components/input/InputWithLable'
import config from '../config/token';
function SubscriptionPlans() {
const [showRoom, setshowRoom] = useState(false)
const [Data ,setData] = useState([])
const [StripeId, setStripeId] = useState('');
const [id, setid] = useState('');
const [planName, setplanName] = useState('');
const [planSId, setplanSId] = useState('');
const [planUser, setplanUser] = useState('');
const [planSDate, setplanSDate] = useState('');
const [planEDate, setplanEDate] = useState('');
const [planPrice, setplanPrice] = useState('');
const [planStatus, setplanStatus] = useState('');

const [mode, setmode] = useState('Create');
const columns = useMemo(() => Plans, [])
const data = useMemo(() =>Data , [Data])
const history = useNavigate()


const defaultColumn = React.useMemo(
  () => ({
    Filter: ColumnFilter
  }),
  []
)

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  state,
  gotoPage,
  pageCount,
  setPageSize,
  prepareRow,
  // setGlobalFilter
} = useTable(
  {
    columns,
    data,
    defaultColumn,
    initialState: { pageIndex: 0 }
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
)
const { pageIndex, pageSize } = state
useEffect(() => {
  
  getData()
  getData1()
}, [])



const getData = async()=>{
  var token =localStorage.getItem('accessToken')
      var config = {
        headers:  {
          "Authorization":'Bearer '  + token,
        }
      }
      await  axios.get('https://api.chopeai.com/api/subscription/get',config)
      .then(function (response) {
        console.log(response.data?.data?.data);
        setData(response.data?.data?.data)
      })
      .catch(function (error) {
        console.log(error.response.statusText);
        if(error.response.statusText === 'Unauthorized'){
          // getToken()
        }
      });
    } 

    const getData1 = async()=>{
      var token =localStorage.getItem('accessToken')
          var config = {
            headers:  {
              "Authorization":'Bearer '  + token,
            }
          }
          await  axios.get('https://api.chopeai.com/api/payment/get',config)
          .then(function (response) {
            console.log(response.data?.data?.data);
            setStripeId(response.data?.data?.data._id)
          })
          .catch(function (error) {
            console.log(error.response.statusText);
            if(error.response.statusText === 'Unauthorized'){
              // getToken()
            }
          });

        }




const AddPlans =()=>{
  setmode('Create')
  setid('')
  setplanEDate('')
  setplanName('')
  setplanPrice('')
  setplanSDate('')
  setplanSId(StripeId)
  setplanStatus('')
  setplanUser('')
  setshowRoom(true)
}
const createplan = async ()=>{
  var obj = {
    "name": planName,
    "strip_id": planSId,
    "user_count":planUser,
    "price": planPrice,
    "start_date": planSDate,
    "end_date": planEDate,
    "status": planStatus
  }
  await  axios.post('https://api.chopeai.com/api/subscription/create',obj,config)
  .then(function (response) {
    console.log(response.data);
    getData()
    setshowRoom(false)
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}
const Updateplan = async ()=>{
  var obj = {
    "subscriptionId": id,
    "name": planName,
    "strip_id": planSId,
    "user_count":planUser,
    "price": planPrice,
    "start_date": planSDate,
    "end_date": planEDate,
    "status": planStatus
  }
  await  axios.post('https://api.chopeai.com/api/subscription/update',obj,config)
  .then(function (response) {
    console.log(response.data);
    getData()
    setshowRoom(false)
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}
const EditRoom = (d)=>{
  setmode('Update')
  setid(d._id)
  setplanEDate(d.end_date)
  setplanName(d.name)
  setplanPrice(d.price)
  setplanSDate(d.start_date)
  setplanSId(d.strip_id)
  setplanStatus(d.status)
  setplanUser(d.user_count)
setshowRoom(true)
}

const cancel = ()=>{
  setid('')
  setplanEDate('')
  setplanName('')
  setplanPrice('')
  setplanSDate('')
  setplanSId('')
  setplanStatus('')
  setplanUser('')
  setshowRoom(false)
}
// 

function exportPDF(id) {
window.print()
}

const reload = ()=>{
  history(0)
}
const reset = ()=>{
  reload()
}

const activeComp =async (d)=>{

  var obj = {
    "subscriptionId": d._id
  }
  await  axios.post('https://api.chopeai.com/api/subscription/enable',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    getData()
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}


const disableComp =async (d)=>{
  console.log(d);
  var obj = {
    "subscriptionId": d._id
  }
  await  axios.post('https://api.chopeai.com/api/subscription/disable',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    getData()
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}

const deleteComp =async (d)=>{
  console.log(d);
  var obj = {
    "subscriptionId": d._id
  }
  await  axios.post('https://api.chopeai.com/api/subscription/delete',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    getData()
  })
  .catch(function (error) {
    console.log(error.response.statusText);
   
  });
}


  return (
    <>
    <div className='appHeader'>
      <p>Subscription Plans</p> 
    </div>
    <div className='table-container border-radius5px box-shadow'>
          <div className='manament-table-header'>
            <span> Plans</span>
            <div className='btn-divder'>
              {/* <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button> */}
              <button className='add-new-btn' onClick={AddPlans}>Add New Plans</button>
            </div>

          </div>
          {/* divder */}
          <div className='justify-center'>
          <span className='divder'></span>
          </div>

          <div className='table-container'>
          <>
      <table {...getTableProps()} id='test'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}
               
                >
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
                                 <span className="table-asc-dec"  onClick={() => column.toggleSortBy()}>
                <span>{column.render('Header')}</span> 
                <span>  {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}</span> 

                  </span>
                </th>
              ))}
              <th><div className="margintop2rem">Action</div></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td className='action-table-btns'>
                  <BiEditAlt {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' />
                  <AiOutlineDelete  onClick={()=>deleteComp(row.original)} className='cursor-pointer' />
                  <MdOutlineDoDisturbAlt  onClick={()=>disableComp(row.original)} className='cursor-pointer' />
                  <BiDetail  onClick={()=>activeComp(row.original)} className='cursor-pointer' />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination-section">


            <div className="pagination-page-section">
            <div>
              <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
                <span>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(pageNumber)
                }}
                style={{ width: '50px' }}
              />
            </span>{' '}
            </div>
                

        <div className="pagination-btn-section">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         <FiChevronsLeft/>
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft/> <span>Previous</span>
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
        <span>Next</span>   <FiChevronRight/>
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight/> 
        </button>
        </div>

                <div className="pagination-select-section">
                     <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 15,20,25,50,100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                   </select>
                </div>

      </div>
          </>
          </div>
        </div>

        {showRoom &&  <div className='add-plan-wrapper'>
    <div className='add-plan-container'>
      <div className='border-blue border-radius15px border-radius5px backgroundColor'>
        <h3>Add Plan</h3>
        <div className='plan-detail-list'>
        <InputWithLable placeholder='Name' callback={(event)=>setplanName(event.target.value)} value={planName}/>
        <InputWithLable placeholder='Strip Id' disabled={true} callback={(event)=>setplanSId(event.target.value)} value={planSId}/>
        <InputWithLable placeholder='User Count' type={'number'} callback={(event)=>setplanUser(event.target.value)} value={planUser}/>
        <InputWithLable placeholder='Price' type={'number'} callback={(event)=>setplanPrice(event.target.value)} value={planPrice}/>
        <InputWithLable placeholder='Start_date' type={'date'} callback={(event)=>setplanSDate(event.target.value)} value={planSDate}/>
        <InputWithLable placeholder='End_date' type={'date'} callback={(event)=>setplanEDate(event.target.value)} value={planEDate}/>
        {/* <InputWithLable placeholder='Status' callback={(event)=>setplanStatus(event.target.value)} value={planStatus}/> */}
        <div className='input-withlable-container subplan'>
        <span>Status</span>
                          <select name="cars" id="cars" className='input-field' onChange={(e)=>setplanStatus(e.target.value)}>
                          <option value="" disabled selected>Status</option>
                                <option   value="Enable" >Enable</option>
                                <option   value="Disable">Disable</option>
                                
                          </select>
                          </div>

        </div>
        <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>Cancel</button>
                  {mode==='Create'?
                  <button className='create-btn' onClick={createplan}>Create</button>
                  :mode==='Update'?
                  <button className='create-btn' onClick={Updateplan}>Update</button>:null
                  }
              </div>
        
      </div>
    </div>
    </div>
    
    }
    </>
  )
}

export default SubscriptionPlans