import React, { useState,useEffect } from "react";
import InputWithLable from '../components/input/InputWithLable'
import '../Styles/PaymentGateway.css'
import axios from "axios";

function PaymentGateway() {
  // const [Data ,setaData] = useState([])
  const [private_key ,setprivate_key] = useState([])
  const [public_key ,setpublic_key] = useState([])
useEffect(() => {
  const getData = async()=>{
    var token =localStorage.getItem('accessToken')
        var config = {
          headers:  {
            "Authorization":'Bearer '  + token,
          }
        }
        await  axios.get('https://api.chopeai.com/api/payment/get',config)
        .then(function (response) {
          console.log(response.data?.data?.data);

          // setaData(response.data?.data?.data[0])
          setprivate_key(response.data?.data?.data.private_key)
          setpublic_key(response.data?.data?.data.public_key)
        })
        .catch(function (error) {
          console.log(error.response.statusText);
          if(error.response.statusText === 'Unauthorized'){
            // getToken()
            alert('Unauthorized')
          }
        });
      } 
      getData()
}, [])
const updatePayment =async ()=>{
  var token =localStorage.getItem('accessToken')
  var config = {
    headers:  {
      "Authorization":'Bearer '  + token,
    }
  }
  var obj = {
    private_key,
    public_key
}
  await  axios.post('https://api.chopeai.com/api/payment/update',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);

    // setaData(response.data?.data?.data[0])
    // setprivate_key(response.data?.data?.data[0].private_key)
    // setpublic_key(response.data?.data?.data[0].public_key)
  })
  .catch(function (error) {
    console.log(error.response.statusText);
    if(error.response.statusText === 'Unauthorized'){
      // getToken()
      alert('Unauthorized')
    }
  });
}
  return (
    <>
    <div className='app-header'> 
      <h1>Payment Gateway</h1>
    </div>
    <div className='table-container border-radius5px box-shadow'>
    <div className='manament-table-header'>
            <span>Skype Payment Gateway</span>
            
          </div>
          <div className='justify-center'>
          <span className='divder'></span>
          </div>
          <div className='textInput-container'>
          <InputWithLable placeholder='Public Key' callback={(event)=>setpublic_key(event.target.value)} value={public_key}/>
          <InputWithLable placeholder='Private Key' callback={(event)=>setprivate_key(event.target.value)} value={private_key} />
          </div>
          <div className='flex-end'>
          <button onClick={updatePayment}>Submit</button>
          </div>
    </div>
  </>
  )
}

export default PaymentGateway