import React,{useEffect,useState} from 'react'
import '../Styles/DashBoard.css'
import axios from 'axios';
import config from '../config/token';
import LineChart from '../components/chart/LineChart'
import LineChart2 from '../components/chart/LineChart2'
function Dashboard() {
 
  const [CompanyData, setCompanyData] = useState([])
  const [TotoalIncome, setTotoalIncome] = useState([])
  const [EarningData, setEarningData] = useState([])
  const [CompReData, setCompReData] = useState([])

  useEffect(() => {
    getData()
    getIncome()
    getEarning()
    getRegister()
  }, [])
  

  //api/dashboard/companiesregister

  const getEarning = async ()=>{
  
    await  axios.get('https://api.chopeai.com/api/dashboard/earning',config)
    .then(function (response) {
      console.log(response.data?.data?.graph);
      setEarningData(response.data?.data?.graph)
     
    })
    .catch(function (error) {
      console.log(error.response.statusText);
     
    });
  }

  const getRegister = async ()=>{
  
    await  axios.get('https://api.chopeai.com/api/dashboard/companiesregister',config)
    .then(function (response) {
      console.log(response.data?.data?.graph);
      setCompReData(response.data?.data?.graph)
     
    })
    .catch(function (error) {
      console.log(error.response.statusText);
     
    });
  }



  const getData = async ()=>{
  
    await  axios.get('https://api.chopeai.com/api/dashboard/totalcompanies',config)
    .then(function (response) {
      console.log(response.data?.data?.data);
      setCompanyData(response.data?.data?.data)
     
    })
    .catch(function (error) {
      console.log(error.response.statusText);
     
    });
  }

  const getIncome = async ()=>{
  
    await  axios.get('https://api.chopeai.com/api/dashboard/totalincome',config)
    .then(function (response) {
      console.log(response.data?.data);
      setTotoalIncome(response.data?.data)
     
    })
    .catch(function (error) {
      console.log(error.response.statusText);
     
    });
  }

  return (
  <>
  <div className='appHeader'>
      <p>Dashboard</p> 
    </div>
    <div className='first-container'>
        <div className='first-container-first'>
           <div className='first-wrappper'>
                <div className='total border-radius5px'>
                    <p>Total companies</p>
                    <p className='blur'></p>
                    <p className='testttt'>{CompanyData.total}</p>
                    <div className='flex-center'>
                      <div className='text-align-center'>
                      <p>{CompanyData.active}</p>
                      <p>Active</p>
                      </div>
                      <div className='text-align-center'>
                      <p>{CompanyData.inactive}</p>
                      <p>InActive</p>
                      </div>
                    </div>
                </div>
                <div className='total border-radius5px'>
                    <p>Total Income</p>
                    <p className='blur'></p>
                    <p className='testttt'>{TotoalIncome.totalincome}</p>
                    <div className='flex-center'>
                      <div className='text-align-center'>
                      <p>{TotoalIncome.weekly}</p>
                      <p>Weekly</p>
                      </div>
                      <div className='text-align-center'>
                      <p>{TotoalIncome.monthly}</p>
                      <p>Monthly</p>
                      </div>
                    </div>
                </div>
           </div>
            <div className='first-wrappper'>
            <table>
                  <tr>
                    <th>Company</th>
                    <th>Contact</th>
                    <th>Country</th>
                  </tr>
                  <tr>
                    <td>Alfreds Futterkiste</td>
                    <td>Maria Anders</td>
                    <td>Germany</td>
                  </tr>
                  <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                    <td>Mexico</td>
                  </tr>
                  <tr>
                    <td>Ernst Handel</td>
                    <td>Roland Mendel</td>
                    <td>Austria</td>
                  </tr>
                  <tr>
                    <td>Island Trading</td>
                    <td>Helen Bennett</td>
                    <td>UK</td>
                  </tr>
                  <tr>
                    <td>Laughing Bacchus Winecellars</td>
                    <td>Yoshi Tannamuri</td>
                    <td>Canada</td>
                  </tr>
                  <tr>
                    <td>Magazzini Alimentari Riuniti</td>
                    <td>Giovanni Rovelli</td>
                    <td>Italy</td>
                  </tr>
          </table>
            </div>
        </div>
        <div className='first-container-second'>
   
            <div>
            <table>
                  <tr>
                    <th>Company</th>
                    <th>Contact</th>
                    <th>Country</th>
                  </tr>
                  <tr>
                    <td>Alfreds Futterkiste</td>
                    <td>Maria Anders</td>
                    <td>Germany</td>
                  </tr>
                  <tr>
                    <td>Centro comercial Moctezuma</td>
                    <td>Francisco Chang</td>
                    <td>Mexico</td>
                  </tr>
                  <tr>
                    <td>Ernst Handel</td>
                    <td>Roland Mendel</td>
                    <td>Austria</td>
                  </tr>
                  <tr>
                    <td>Island Trading</td>
                    <td>Helen Bennett</td>
                    <td>UK</td>
                  </tr>
                  <tr>
                    <td>Laughing Bacchus Winecellars</td>
                    <td>Yoshi Tannamuri</td>
                    <td>Canada</td>
                  </tr>
                  <tr>
                    <td>Magazzini Alimentari Riuniti</td>
                    <td>Giovanni Rovelli</td>
                    <td>Italy</td>
                  </tr>
          </table>
            </div>
            
           
            <LineChart2 data={EarningData && EarningData.weekdata} data2={EarningData && EarningData.amountdata} />
        </div>
    </div>
    <div className='second-container'>
      <div><LineChart data={CompReData && CompReData}/></div>
      <div></div>
    </div>
  </>
  )
}

export default Dashboard