
import { IoGridOutline } from 'react-icons/io5';
import { BsBarChart } from 'react-icons/bs';
import { MdOutlineMail } from 'react-icons/md';
import { MdSettingsSuggest } from 'react-icons/md';
import { BiUserVoice } from 'react-icons/bi';
import { SiAuthy } from 'react-icons/si';

export const obj = [
    {  
    "id":1,
    "icon":<IoGridOutline/>,
    "menu_name":"Dashboard",
    "route":"/"
    },
    {  
    "id":2,
    "icon":<MdSettingsSuggest/>,
    "menu_name":"Companies",
    "route":"companies"
    },
    {  
    "id":3,
    "icon":<BiUserVoice/>,
    "menu_name":"Payment Gateway",
    "route":"payment-gateway"
    },
    {  
        "id":3,
        "icon":<SiAuthy/>,
        "menu_name":"Authentication",
        "route":"authentication"
    },
    
    {  
    "id":4,
    "icon":<BiUserVoice/>,
    "menu_name":"Subscription Plans",
    "route":"subscription-plans"
    },
    {  
    "id":5,
    "icon":<BsBarChart/>,
    "menu_name":"Invoices",
    "route":"invoices"
    },
    {  
    "id":6,
    "icon":<MdOutlineMail/>,
    "menu_name":"Super Admins",
    "route":"super-admins"
    },
    {  
    "id":7,
    "icon":<MdOutlineMail/>,
    "menu_name":"Setting",
    "route":"setting"
    }

]
