import React, {  useState } from "react";
import '../Styles/Login.css'
import logo from '../assests/logo.png'
import InputField from '../components/input/InputField'
import { VscLock} from 'react-icons/vsc'
import { BiUser} from 'react-icons/bi'
import { Link } from "react-router-dom";
import axios from "axios";
import {Navigate } from "react-router-dom";
function Login() {
  const [name, setName] = useState('');
  const [password, setpassword] = useState('');
  const [login, setlogin] = useState('');
  let auth = JSON.parse(localStorage.getItem('user')) 
  console.log(auth);
  if(auth){
    return <Navigate to='/'/>
  }
const handleLogin = async()=>{
if(!name){
  alert('Please Enter Name')
}else if(!password){
  alert('Please Enter Password')
}
else{
  var obj ={
    email:name,
    password
  }
  await  axios.post('https://api.chopeai.com/api/superadmin/login',obj)
  .then(function (response) {
    console.log(response.data);
    if(response.data.message==='successfully login'){
    setlogin(response.data.message)
    localStorage.setItem( 'user', JSON.stringify(response.data.data))
    localStorage.setItem('accessToken',response.data.data.token.accessToken)
    localStorage.setItem('refreshToken',response.data.data.token.refreshToken)
    var today = new Date();
   
    localStorage.setItem('call', today.setHours(today.getHours() + 1))
    }
    window.location.reload()
    // if (response.status === 404) {
    //   alert(response.data.message)
    // }
  })
  .catch(function (error) {
    console.log(error);
    if (error.response.status === 404) {
      alert(error.response.data.message)
    }
  });
}

}
  return (
    <div className='login-wrapper'>
    <div className='login-container border-blue'>
      <div className='loin-logo'>
        <img src={logo} alt='logo'/>
      </div>
      <p>Welcome to Chope AI</p>
      <h6>Sign in to continue</h6>
      {login && login}
      <InputField icon={<BiUser/>} placeholder='UserName' type='text' callback={(event)=>setName(event.target.value)} value={name}/>
      <InputField icon={<VscLock/>} placeholder='Password' type='password' callback={(event)=>setpassword(event.target.value)} value={password}/>
      {/* <input placeholder='userName' type='text'/>
      <input placeholder='password' type='password'/> */}
      <button className='sign-in-btn' onClick={handleLogin}>Sign In</button>
      <h2><span>OR</span></h2>
      <h6> <Link to='/forget-password'>Forget Password?</Link></h6>
     {/* <h6>Don't have an account ? <Link to='/register'>Register</Link></h6>   */}
    </div>
    </div>

  )
}

export default Login