import '../Styles/global.css'
import '../Styles/SubscriptionPlans.css'
import '../Styles/Authentication.css'
import React, { useMemo,useState,useEffect } from "react";
import { FiChevronLeft,FiChevronRight,FiChevronsLeft,FiChevronsRight} from 'react-icons/fi';
import { useTable,usePagination,useFilters, useGlobalFilter ,useSortBy } from 'react-table'
import { facilitylist,authList } from '../helper/columns'
import { AiOutlineCalendar } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlineDoDisturbAlt } from 'react-icons/md';
import { BiDetail } from 'react-icons/bi';

import { ColumnFilter } from '../components/table_filter/ColumnFilter'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InputWithLable from '../components/input/InputWithLable'
import config from '../config/token';

function Authentication() {
const [showRoom, setshowRoom] = useState(false)
const [authTable, setauthTable] = useState(false)
const [faciTable, setfaciTable] = useState(true)
const [showFaciTable, setshowFaciTable] = useState(false)
const [showauthTable, setshowauthTable] = useState(false)

const [fecility, setfecility] = useState("")
const [Auth, setAuth] = useState("")
const [AuthOne, setAuthOne] = useState('')

const [Data ,setData] = useState([])
const columns = useMemo(() => facilitylist, [])
const columns1 = useMemo(() => authList, [])
const data = useMemo(() =>Data , [Data])
const history = useNavigate()


const defaultColumn = React.useMemo(
  () => ({
    Filter: ColumnFilter
  }),
  []
)

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  nextPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  state,
  gotoPage,
  pageCount,
  setPageSize,
  prepareRow,
  // setGlobalFilter
} = useTable(
  {
    columns: authTable?columns1:faciTable?columns:null,
    data,
    defaultColumn,
    initialState: { pageIndex: 0 }
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
)
const { pageIndex, pageSize } = state

useEffect(() => {
    facility()
    document.getElementById('fac').style.backgroundColor = '#3a5691a1'
    document.getElementById('auth').style.backgroundColor = 'transparent'
  }, [])
  
const facility = async ()=>{
  document.getElementById('fac').style.backgroundColor = '#3a5691a1'
  document.getElementById('auth').style.backgroundColor = 'transparent'
    await  axios.get('https://api.chopeai.com/api/facility/getall',config)
    .then(function (response) {
      console.log(response.data?.data?.data);
      setData(response.data?.data?.data)
      setfaciTable(true)
      setauthTable(false)
    })
    .catch(function (error) {
      console.log(error.response.statusText);
      if(error.response.statusText === 'Unauthorized'){
        // getToken()
      }
    });
}

const auth = async ()=>{
  document.getElementById('auth').style.backgroundColor = '#3a5691a1'
  document.getElementById('fac').style.backgroundColor = 'transparent'
    await  axios.get('https://api.chopeai.com/api/authentication/get',config)
    .then(function (response) {
      console.log(response.data?.data?.data);
      setData(response.data?.data?.data)
      setauthTable(true)
      setfaciTable(false)
    })
    .catch(function (error) {
      console.log(error.response.statusText);
      if(error.response.statusText === 'Unauthorized'){
        // getToken()
      }
    });
}
const AddFaci = ()=>{
// alert('Facility')
setshowFaciTable(true)

}

const AddAuth = ()=>{
setAuth('')
setshowauthTable(true)
setAuthOne('')
}

const cancel = ()=>{
  setshowFaciTable(false)
  setshowauthTable(false)
}

const AddFacility =async ()=>{
  var obj = {
    "name":fecility
  }


  await  axios.post('https://api.chopeai.com/api/facility/add',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    // setData(response.data?.data?.data)
    // setfaciTable(true)
    // setauthTable(false)
    setshowFaciTable(false)
  })
  .catch(function (error) {
    console.log(error.response.statusText);
  });
}

const AddAuthNew =async ()=>{
  var obj = {
    "name":Auth
  }


  await  axios.post('https://api.chopeai.com/api/authentication/add',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    // setData(response.data?.data?.data)
    // setfaciTable(true)
    // setauthTable(false)
    auth()
    setshowauthTable(false)
  })
  .catch(function (error) {
    console.log(error.response.statusText);
  });
}
const EditRoom = (d)=>{
console.log(d);
setAuth(d.name)
setAuthOne(d)
setshowauthTable(true)
}

const deleteComp =async (d)=>{
  console.log(d);
  var obj = {
    "auth_id":d._id
  }


  await  axios.post('https://api.chopeai.com/api/authentication/delete',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    // setData(response.data?.data?.data)
    // setfaciTable(true)
    // setauthTable(false)
    auth()
    setshowauthTable(false)
  })
  .catch(function (error) {
    console.log(error.response.statusText);
  });
}

const UpdateAuth =async ()=>{

  var obj = {
    "auth_id":AuthOne._id,
    "name":Auth
  }


  await  axios.post('https://api.chopeai.com/api/authentication/update',obj,config)
  .then(function (response) {
    console.log(response.data?.data?.data);
    // setData(response.data?.data?.data)
    // setfaciTable(true)
    // setauthTable(false)
    auth()
    setshowauthTable(false)
  })
  .catch(function (error) {
    console.log(error.response.statusText);
  });
}

  return (
    <>
        <div className='appHeader'>
            <p>Authentication</p> 
        </div>
        <div>
            <button id='fac' className='btnAuth' onClick={facility}>Facility Access</button>
            <button id='auth' className='btnAuth' onClick={auth}>Authentication</button>
        </div>
        {authTable && <div className='table-container border-radius5px box-shadow'>
          <div className='manament-table-header'>
            <span> Authentication</span>
            <div className='btn-divder'>
              {/* <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button> */}
              <button className='add-new-btn' onClick={AddAuth}>Add New Authentication </button>
            </div>

          </div>
          {/* divder */}
          <div className='justify-center'>
          <span className='divder'></span>
          </div>

          <div className='table-container'>
          <>
      <table {...getTableProps()} id='test'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}
               
                >
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
                                 <span className="table-asc-dec"  onClick={() => column.toggleSortBy()}>
                <span>{column.render('Header')}</span> 
                <span>  {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}</span> 

                  </span>
                </th>
              ))}
              {/* <th><div className="margintop2rem">Action</div></th> */}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td className='action-table-btns'>
                  {/* <BiEditAlt {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' />
                  <AiOutlineDelete  onClick={()=>deleteComp(row.original)} className='cursor-pointer' />
                  <MdOutlineDoDisturbAlt  onClick={()=>disableComp(row.original)} className='cursor-pointer' />
                  <BiDetail  onClick={()=>activeComp(row.original)} className='cursor-pointer' /> */}
                   {authTable && <><BiEditAlt {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' />
                  <AiOutlineDelete  onClick={()=>deleteComp(row.original)} className='cursor-pointer' />
                  </>}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination-section">


            <div className="pagination-page-section">
            <div>
              <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
                <span>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(pageNumber)
                }}
                style={{ width: '50px' }}
              />
            </span>{' '}
            </div>
                

        <div className="pagination-btn-section">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         <FiChevronsLeft/>
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft/> <span>Previous</span>
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
        <span>Next</span>   <FiChevronRight/>
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight/> 
        </button>
        </div>

                <div className="pagination-select-section">
                     <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 15,20,25,50,100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                   </select>
                </div>

      </div>
          </>
          </div>
        </div>}

        {faciTable && <div className='table-container border-radius5px box-shadow'>
          <div className='manament-table-header'>
            <span> Facility Access</span>
            <div className='btn-divder'>
              {/* <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button> */}
              <button className='add-new-btn' onClick={AddFaci}>Add New Facility Access</button>
            </div>

          </div>
          {/* divder */}
          <div className='justify-center'>
          <span className='divder'></span>
          </div>

          <div className='table-container'>
          <>
      <table {...getTableProps()} id='test'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}
               
                >
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
                                 <span className="table-asc-dec"  onClick={() => column.toggleSortBy()}>
                <span>{column.render('Header')}</span> 
                <span>  {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}</span> 

                  </span>
                </th>
              ))}
              {/* <th><div className="margintop2rem">Action</div></th> */}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td className='action-table-btns'>
                  {/* <BiEditAlt {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' />
                  <AiOutlineDelete  onClick={()=>deleteComp(row.original)} className='cursor-pointer' />
                  <MdOutlineDoDisturbAlt  onClick={()=>disableComp(row.original)} className='cursor-pointer' />
                  <BiDetail  onClick={()=>activeComp(row.original)} className='cursor-pointer' /> */}

                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination-section">


            <div className="pagination-page-section">
            <div>
              <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
          </div>
                <span>
              | Go to page:{' '}
              <input
                type='number'
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(pageNumber)
                }}
                style={{ width: '50px' }}
              />
            </span>{' '}
            </div>
                

        <div className="pagination-btn-section">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
         <FiChevronsLeft/>
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        <FiChevronLeft/> <span>Previous</span>
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
        <span>Next</span>   <FiChevronRight/>
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        <FiChevronsRight/> 
        </button>
        </div>

                <div className="pagination-select-section">
                     <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}>
                    {[10, 15,20,25,50,100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                   </select>
                </div>

      </div>
          </>
          </div>
        </div>}

{
  showFaciTable &&  <div className='add-company-wrapper'>
  <div className='add-company-container'>
    <div className='border-blue border-radius15px border-radius5px backgroundColor'>
      <h3>Add Facility</h3>
      <div className='facility-detail-list'>
      <InputWithLable placeholder='Facility' callback={(event)=>setfecility(event.target.value)} value={fecility}/>

            <div className='btns'>
                <button className='create-btn cancel-btn' onClick={cancel}>Cancel</button>
                <button className='create-btn' onClick={AddFacility}>Create</button>
                {/* <button className='create-btn' onClick={UpdateFacility}>Update</button> */}
            </div>

      </div>

      
    </div>
  </div>
  </div>
}

{
  showauthTable &&  <div className='add-company-wrapper'>
  <div className='add-company-container'>
    <div className='border-blue border-radius15px border-radius5px backgroundColor'>
      <h3>Add Authentication</h3>
      <div className='facility-detail-list'>
      <InputWithLable placeholder='Authentication' callback={(event)=>setAuth(event.target.value)} value={Auth}/>

            <div className='btns'>
                <button className='create-btn cancel-btn' onClick={cancel}>Cancel</button>
                {AuthOne.length >= 0?<button className='create-btn' onClick={AddAuthNew}>Create</button>:
                <button className='create-btn' onClick={UpdateAuth}>Update</button>}
            </div>

      </div>

      
    </div>
  </div>
  </div>
}


    </>

  )
}

export default Authentication