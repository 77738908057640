import React, {  useState } from "react";
import '../Styles/Login.css'
import logo from '../assests/logo.png'
import InputField from '../components/input/InputField'
import { HiOutlineKey,HiOutlineLockClosed} from 'react-icons/hi'
import axios from "axios";
// import {Navigate } from "react-router-dom";
import { BiUser} from 'react-icons/bi'
// import { Link } from "react-router-dom";
import config from '../config/token';
function ForegetPassword() {
  const [email, setEmail] = useState('');
  const [cpassword, setcpassword] = useState('');
  const [password, setpassword] = useState('');
  const [otp, setotp] = useState('');
  const [open, setOpen] = useState(false);
  const handleClick =async ()=>{
    if (email === ''|| email === undefined || email === null) {
      alert('Please Fill')
    }
    else{
      var obj = {
        email
      }
      await  axios.post('https://api.chopeai.com/api/superadmin/passwordrequest',obj,config)
      .then(function (response) {
        console.log(response.data?.data?.data);
        setOpen(true)
      })
      .catch(function (error) {
        console.log(error.response.statusText);
       
      });
    }
  }
  const changePasssword =async ()=>{
    if (otp === ''|| otp === undefined || otp === null) {
      alert('Please Fill otp')
    }
   else if (password === ''|| password === undefined || password === null) {
      alert('Please Fill password')
    }
    else if (cpassword === ''|| cpassword === undefined || cpassword === null) {
      alert('Please Fill cpassword')
    }

     else{
      if ( password === cpassword) {
        var obj = {
          otp,
          password
        }
        await  axios.post('https://api.chopeai.com/api/superadmin/otpverify',obj,config)
        .then(function (response) {
          console.log(response.data?.data?.data);
          setOpen(true)
        })
        .catch(function (error) {
          console.log(error.response.statusText);
         
        });
      }
      else{
        alert('password mismatch')
      }

    }
  }
  return (
    <div className='login-wrapper'>
    <div className='login-container border-blue'>
      <div className='loin-logo'>
        <img src={logo} alt='logo'/>
      </div>
      <p>Welcome to Chope AI</p>
      <h6>Sign in to continue</h6>
      { !open &&
        <><InputField icon={<BiUser/>} placeholder='Enter Your Email' type='text' callback={(event)=>setEmail(event.target.value)} value={email}/>
      <button className='sign-in-btn' onClick={handleClick}>Get OTP</button>
      </>}
      {open && 
      <><InputField icon={<HiOutlineLockClosed/>} placeholder='OTP' type='text' callback={(event)=>setotp(event.target.value)} value={otp}/>
      <InputField icon={<HiOutlineKey/>} placeholder='New Pin' type='text' callback={(event)=>setpassword(event.target.value)} value={password}/>
      <InputField icon={<HiOutlineKey/>} placeholder='Re-Type Pin' type='text' callback={(event)=>setcpassword(event.target.value)} value={cpassword}/>
      <button className='sign-in-btn' onClick={changePasssword}>Change Passsword</button>
      </>}
      {/* <h2><span>OR</span></h2> */}
      {/* <h6> <Link to='/forget-password'>Forget Password?</Link></h6> */}
     {/* <h6>Don't have an account ? <Link to='/register'>Register</Link></h6>   */}
    </div>
    </div>
  )
}

export default ForegetPassword